const comMain = () => {
  if (document.querySelector("section#jsComSecMain") !== null) {



    let sec = document.querySelector("#jsComSecMain")

    let bgWrap = sec.querySelector(".js-bg-wrap")
    let coverWrap = sec.querySelector(".js-cover-wrap")
    let imgBox = bgWrap.querySelector(".js-img-box")
    let blurBox = bgWrap.querySelector(".js-blur-box")

    let mainWrap = sec.querySelector(".js-main-wrap")
    let ttlBox = mainWrap.querySelector(".js-ttl-box")


    {
      let pin = gsap.to(bgWrap, {
        scrollTrigger: {
          trigger: bgWrap,
          start: '100% 100%',
          endTrigger: 'body',
          end: '100% 0%',
          pin: true,
          pinSpacing: false,
          // markers: true
        }
      })

      let blur = gsap.to(blurBox, {
        backdropFilter: 'blur(10px)',
        ease: "none",
        scrollTrigger: {
          trigger: sec,
          start: '0% 0%',
          end: '100% 100%',
          scrub: 0,
          // markers: true
        }
      })

      let prlx = gsap.to(imgBox, {
        y: 200,
        ease: "none",
        scrollTrigger: {
          trigger: sec,
          start: '0% 0%',
          end: '100% 100%',
          scrub: 0,
          // markers: true
        }
      })

      let end = gsap.to(sec, {
        scrollTrigger: {
          trigger: sec,
          start: '100% 0%',
          // end: '100% 100%',
          onEnter: () => sec.classList.add('hidden'),
          // onLeave: () => console.log("onLeave"),
          onEnterBack: () => sec.classList.remove('hidden'),
          // onLeaveBack: () => console.log("onLeaveBack"),
          // markers: true
        }
      })

      let target = document.querySelector('body');
      let observer = new ResizeObserver((entries) => {
        entries.forEach((entry) => {
          pin.scrollTrigger.refresh()
          blur.scrollTrigger.refresh()
          prlx.scrollTrigger.refresh()
          end.scrollTrigger.refresh()
        });
      });
      observer.observe(target);
    }


    setTimeout(() => {
      // imgBox.classList.add('act')
      coverWrap.classList.add('act')
    }, 400);

    setTimeout(() => {
      ttlBox.classList.add('act')
    }, 1800);



  }
}
export default comMain;